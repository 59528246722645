<template>
  <div>
    <v-tabs fixed-tabs background-color="indigo" dark>
      <v-tab>
        {{ $t("routes.user") }}
      </v-tab>
      <v-tab v-if="permitted('SuperAdmin')">
        {{ $t("auditLog.title") }}
      </v-tab>

      <v-tab-item :key="0">
        <v-data-table
          :headers="headers"
          :items="companyUsers"
          :items-per-page="50"
          class="elevation-1"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ humanDate(item.createdAt, "dd MMM yyyy - HH:mm") }}
          </template>

          <template v-slot:[`item.role`]="{ item }">
            <RoleSelector v-model="item.userRoleId" :user-id="item.userId" />
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              dark
              color="primary"
              @click="clickedRow(item)"
              v-if="
                (item.userId === currentUser.userId ||
                  permitted('User.Update')) &&
                isSuperAndIsLower(item.userRoleId)
              "
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table>

        <div
          v-if="
            canAccessFeature(FEATURES.MULTI_COMPANY) && externalUsers.length > 0
          "
        >
          <h3 class="pa-2">
            {{ $t("company.externalUsers") }}
          </h3>
          <v-data-table
            v-if="externalUsers.length > 0"
            :headers="headerBase"
            :items="externalUsers"
            :items-per-page="50"
            class="elevation-1"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ humanDate(item.createdAt, "dd MMM yyyy - HH:mm") }}
            </template>

            <template v-slot:[`item.role`]="{ item }">
              <RoleSelector
                disabled
                v-model="item.userRoleId"
                :user-id="item.userId"
              />
            </template>
          </v-data-table>
        </div>
      </v-tab-item>

      <v-tab-item :key="1">
        <audit-log-list />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RoleSelector from "../roles/RoleSelector.vue";
import AuditLogList from "../auditLog/AuditLogList.vue";
import companyRepository from "../../api/repositories/companyRepository";

export default {
  name: "CompanyUsers",

  components: { RoleSelector, AuditLogList },

  data() {
    const headerBase = [
      { text: this.$t("user.email"), value: "email" },
      { text: this.$t("common.createdAt"), value: "createdAt" },
      { text: this.$t("user.role"), value: "role" },
    ];

    return {
      externalUsers: [],
      headerBase: headerBase,
      headers: [
        ...headerBase,
        { text: this.$t("user.edit"), value: "actions" },
      ],
    };
  },

  computed: {
    ...mapState("roles", ["roles"]),
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("companies", ["companyUsers"]),
  },

  methods: {
    ...mapActions("companies", ["getCompanyUsers"]),
    ...mapActions("users", ["updateRole", "getById"]),
    ...mapActions("roles", ["getRolesByCompany"]),

    async clickedRow(item) {
      if (
        item.userId === this.currentUser.userId ||
        this.permitted("User.Update")
      ) {
        await this.getById(item.userId);
        this.$router.push(`/company/user/${item.userId}`);
      }
    },

    isSuperAndIsLower(r) {
      if (this.permitted("SuperAdmin")) return true;

      var foundRole = this.roles.find((e) => e.userRoleId == r);
      if (foundRole && foundRole.defaultRoleKey == "SuperAdmin") return false;

      return true;
    },
  },

  async created() {
    await this.getCompanyUsers();
    await this.getRolesByCompany();
    this.externalUsers = await companyRepository
      .getAllExternalUsers()
      .then((d) => d.data)
      .catch(() => []);
  },
};
</script>

<style></style>
