var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"indigo","dark":""}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t("routes.user"))+" ")]),(_vm.permitted('SuperAdmin'))?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t("auditLog.title"))+" ")]):_vm._e(),_c('v-tab-item',{key:0},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.companyUsers,"items-per-page":50},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.humanDate(item.createdAt, "dd MMM yyyy - HH:mm"))+" ")]}},{key:`item.role`,fn:function({ item }){return [_c('RoleSelector',{attrs:{"user-id":item.userId},model:{value:(item.userRoleId),callback:function ($$v) {_vm.$set(item, "userRoleId", $$v)},expression:"item.userRoleId"}})]}},{key:`item.actions`,fn:function({ item }){return [(
              (item.userId === _vm.currentUser.userId ||
                _vm.permitted('User.Update')) &&
              _vm.isSuperAndIsLower(item.userRoleId)
            )?_c('v-icon',{attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.clickedRow(item)}}},[_vm._v("mdi-pencil")]):_vm._e()]}}],null,true)}),(
          _vm.canAccessFeature(_vm.FEATURES.MULTI_COMPANY) && _vm.externalUsers.length > 0
        )?_c('div',[_c('h3',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.$t("company.externalUsers"))+" ")]),(_vm.externalUsers.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerBase,"items":_vm.externalUsers,"items-per-page":50},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.humanDate(item.createdAt, "dd MMM yyyy - HH:mm"))+" ")]}},{key:`item.role`,fn:function({ item }){return [_c('RoleSelector',{attrs:{"disabled":"","user-id":item.userId},model:{value:(item.userRoleId),callback:function ($$v) {_vm.$set(item, "userRoleId", $$v)},expression:"item.userRoleId"}})]}}],null,true)}):_vm._e()],1):_vm._e()],1),_c('v-tab-item',{key:1},[_c('audit-log-list')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }